<div class="flex-vertical flex-centered">
  <img src="assets/logo.png" />

  <cm-password-change (submit)="submit($event)" (cancel)="goToLogin()" [disabled]="disableForm"></cm-password-change>

  <div class="submission-message">{{ message }}</div>

  <button *ngIf="success" mat-flat-button color="primary" class="submit-btn" (click)="goToLogin()">
    <span>Ir para Login </span><mat-icon>login</mat-icon>
  </button>
  
</div>

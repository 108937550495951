// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DocumentTemplate, DocumentTemplateApiData } from 'src/app/settings/documents/document-template.model';
import {
  DataRawType,
  DataType,
  Variable,
  VARIABLE_PLACEHOLDER_REGEX,
  VariableMap,
  VariableTransformation,
  VariableType,
} from 'src/app/shared/constants/global.constants';
import { CustomFormFieldLabel } from 'src/app/shared/constants/forms.constants';
import blueimpmd5 from 'blueimp-md5';

export type EntityField = {
  name: string;
  type: DataType;
  rawType: DataRawType;
  label?: CustomFormFieldLabel;
};

export type EntityFields = EntityField[];

export type EntitiesFields = {
  [key: string]: EntityField[];
};

@Injectable()
export class ConfigurationsService {
  private entitiesFields: EntitiesFields;

  private variables$ = new BehaviorSubject<Variable[]>(null);

  constructor(private http: HttpClient) {}

  getDocuments(): Observable<DocumentTemplate[]> {
    return this.getConfiguration('templates').pipe(map((documents) => documents.map((document) => new DocumentTemplate(document))));
  }

  saveDocument(document: DocumentTemplate) {
    const request = document.id
      ? this.http.put<DocumentTemplateApiData>(`configuration/templates/${document.id}`, document)
      : this.http.post<DocumentTemplateApiData>('configuration/templates', document);

    return request.pipe(map((template) => new DocumentTemplate(template)));
  }

  deleteDocument(document: DocumentTemplate) {
    return this.http.delete(`configuration/templates/${document.id}`);
  }

  getEntitiesFields() {
    return this.entitiesFields ? of(this.entitiesFields) : this.fetchEntitiesFields();
  }

  getVariables() {
    return this.variables$.value ? of(this.variables$.value) : this.fetchVariables();
  }

  getVariableContentResult(content: string, data: any, variables?: Variable[]): Observable<string> {
    return (variables ? of(variables) : this.getVariables()).pipe(
      map((variables) => {
        let result = content;

        const getObjectValueFromPath = (obj: any, path: string) => {
          return path.split('.').reduce((prev, curr) => prev && prev[curr], obj);
        };

        variables.forEach((variable) => {
          if (!content.includes(variable.placeholder)) {
            return;
          }

          let value = '';

          if (variable.type === VariableType.ENTITY) {
            value = getObjectValueFromPath(data, `${variable.entity}.${variable.path}`);

            if (!value) {
              return;
            }

            if (variable.transformation) {
              switch (variable.transformation) {
                case VariableTransformation.FIRST_WORD:
                  value = value.split(' ')[0];
                  break;
                case VariableTransformation.LAST_WORD:
                  value = value.split(' ')[value.split(' ').length - 1];
                  break;
                case VariableTransformation.MD5:
                  value = blueimpmd5(value);
                  break;
              }
            }
          }

          if (variable.type === VariableType.GLOBAL) {
            switch (variable.name) {
              case 'myClubURL':
                value = `${variable.value}`;
                break;
            }
          }

          result = result.replace(variable.placeholder, value);
        });

        return result;
      })
    );
  }

  mapPlaceholdersToVariables(content: string) {
    return this.getVariables().pipe(
      map((variables) => {
        variables.forEach((variable) => {
          if (variable.type === VariableType.GLOBAL) {
            content = content.replace(variable.placeholder, `{global.${variable.name}}`);
            return;
          }

          const key = `{${variable.entity}.${variable.path}${variable.transformation ? ':' : ''}${variable.transformation ? variable.transformation : ''}}`;
          content = content.replace(variable.placeholder, key);
        });

        return content;
      })
    ).toPromise();
  }

  mapVariablesToPlaceholders(content: string) {
    return this.getVariables().pipe(
      map((variables) => {
        variables.forEach((variable) => {
          const key = `{${variable.entity}.${variable.path}${variable.transformation ? ':' : ''}${variable.transformation ? variable.transformation : ''}}`;
          content = content.replace(key, variable.placeholder);
        });

        return content;
      })
    ).toPromise();
  }

  private fetchEntitiesFields() {
    return this.http.get<EntitiesFields>(`configuration/entities-fields`).pipe(tap((fields) => (this.entitiesFields = fields)));
  }

  private fetchVariables() {
    return this.http.get<VariableMap>(`configuration/variables`).pipe(
      map((variablesMap) =>
        Object.keys(variablesMap).reduce((acc, contextVariables) => [...acc, ...variablesMap[contextVariables]], [] as Variable[])
      ),
      tap((variables) => this.variables$.next(variables))
    );
  }

  private getConfiguration(name: string): Observable<any> {
    return this.http.get(`configuration/${name}`);
  }
}

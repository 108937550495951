import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'cm-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss'],
})
export class UserActivationComponent implements OnInit {
  email: string = 'testes';
  token: string = 'as85a6d6a87bda8d65a8dn9adad';

  disableForm = false;
  success = false;
  message: string;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.email = params.email;
      this.token = params.token;
    });
  }

  submit(password: string) {
    this.message = null;

    this.authService
      .activateUser(this.email, this.token, password)
      .pipe(
        catchError((error) => {
          this.message = 'An error ocurred activating your password';

          throw error;
        })
      )
      .subscribe(() => {
        this.message = 'A tua conta foi ativada com sucesso! Podes agora fazer login!';
        this.success = true;
        this.disableForm = true;
      });
  }

  goToLogin() {
    this.router.navigate(['login']);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cm-empty-list-placeholder',
  templateUrl: './empty-list-placeholder.component.html',
  styleUrls: ['./empty-list-placeholder.component.scss'],
})
export class EmptyListPlaceholderComponent implements OnInit {
  @Input() placeholder: string = 'Lista vazia';

  @Input() addNeededScopes: string[] = [];
  @Input() addLabel: string = 'Adicionar';
  @Input() canAdd: boolean = true;
  @Output() add = new EventEmitter<void>();

  constructor() {}

  get hasAddObservers() {
    return Boolean(this.add.observers.length);
  }

  ngOnInit(): void {}

  addCallback() {
    this.add.emit();
  }
}
